import React, { useState, useEffect, useMemo } from "react";
import { usePromiseTracker } from "react-promise-tracker";
import { trackPromise } from 'react-promise-tracker';
import { Link } from 'react-router-dom';

import '../../styles/Admin.css';

import Nav from '../Nav';
import Loader from 'react-loader-spinner';
import Pagination from '@material-ui/lab/Pagination';

import CandidateList from '../CandidateList';
import Filters from "../Filters";

import { useAuth } from '../../services/authentication'

import { addFavorite, getFavorites, deleteFavorite } from "../../services/favorites";
import { getCandidates } from "../../services/candidates";

function Dashboard() {

    /* context */

    const { currentUser } = useAuth()

    /* states */

    const [allCandidates, setAllCandidates] = useState([]);
    const [currentPage, setCurrentPage] = useState(1);
    const [pageCount, setPageCount] = useState(0);
    const [totalCount, setTotalCount] = useState(0);
    const [query, setQuery] = useState('');

    /* methods */

    const isFavorites = useMemo(() => window.location.pathname === '/favorites', [window.location.pathname]);

    useEffect(() => {
        if (isFavorites) {
            bringFavs(currentPage);
        } else {
            bringThemAll(currentPage);
        }
    }, [isFavorites, currentPage, query]);

    const LoadingIndicator = props => {
        const { promiseInProgress } = usePromiseTracker();

        return (
            promiseInProgress &&
            <div style={{
                width: "100%",
                height: "100",
                display: "flex",
                justifyContent: "center",
                alignItems: "center"
            }}
            >
                <Loader type="ThreeDots" color="#383838" height="100" width="100" />
            </div>
        );
    }

    const bringThemAll = async (page) => {
        trackPromise(getCandidates(page, '', query).then(res => {
            if (res !== undefined) {
                setAllCandidates(res.users);
                setPageCount(res.totalPages);
                setTotalCount(res.totalItems);
                setCurrentPage(res.currentPage > res.totalPages ? 1 : res.currentPage);
            }
        }))
    }

    const bringFavs = async (page) => {
        trackPromise(getFavorites(page).then(res => {
            if (res !== undefined) {
                setAllCandidates(res.users);
                setPageCount(res.totalPages);
                setTotalCount(res.totalItems);
                setCurrentPage(res.currentPage > res.totalPages ? 1 : res.currentPage);
            }
        }))
    }

    const applyFilter = (filterQuery) => {
        setQuery(filterQuery);
    }

    async function toggleFavorite(candidateId) {
        const updateCandidate = allCandidates.find(candidate => candidate.candidateId === candidateId);
        if (updateCandidate.favorite) {
            await deleteFavorite(updateCandidate.candidateId);
            updateCandidate.favorite = false;
        } else {
            await addFavorite(updateCandidate.candidateId);
            updateCandidate.favorite = true;
        }
        const candidates = allCandidates.map(candidate => {
            if (candidate.candidateId === candidateId) {
                return updateCandidate;
            }
            return candidate;
        });
        setAllCandidates(candidates);
    }

    return (
        <main>
            <Nav />
            <div className="container main">
                <div className="row align-items-center">
                    <div className="col-12">
                        <h1>Hello {currentUser},</h1>
                    </div>
                    <div className="col-12 col-md-8 mb-3">
                        <div className="card">
                            <div className="card-body bg-dark-yellow">
                                <div className="row">
                                    <div className="col-12 col-md-3 text-center">
                                        <div className="number-circle">{totalCount}</div>
                                    </div>
                                    <div className="col-12 col-md-9 ">
                                        <p className="desc-stats">Number of {isFavorites ? 'Favorites' : 'Candidates'}</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-md-4">
                        {(isFavorites &&
                            <Link to="/dashboard" className="statis-link">Return <i className="fa fa-arrow-right" /></Link>
                        ) ||
                            <Link to="/favorites" className="statis-link">My Favorites<i className="far fa-heart" /></Link>
                        }
                    </div>
                </div>
                <Filters applyFilter={applyFilter} />
                <hr />
                <LoadingIndicator />
                <div className="card card-body mb-5">
                    <CandidateList candidates={allCandidates} toggleFavorite={toggleFavorite} />
                    <div className={"text-center justify-content-center mt-5 d-flex"}>
                        <Pagination count={pageCount} page={currentPage} onChange={(event, value) => setCurrentPage(value)} />
                    </div>
                </div>
            </div>
            <footer className="bg-grey text-center text-lg-start">
                <div className="text-center p-3 text-white">
                    BRASA © {new Date().getFullYear()} Copyright
                </div>
            </footer>
        </main>
    );
}

export default Dashboard;