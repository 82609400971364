import React from "react";
import {Redirect, Route} from "react-router-dom";
import {useAuth} from "./services/authentication";

export const ProtectedRoute = ({component: Component, ...rest}) => {
    const { currentUser } = useAuth();
    return (
        <Route
            {...rest}
            render={props => {
                return !!currentUser ? <Component {...props} user={currentUser} /> : <Redirect to={"/"}/>
            }}
        />
    );
}