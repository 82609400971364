import React, { useState, useEffect } from "react";
import '../styles/Admin.css';
import Tooltip from '@material-ui/core/Tooltip';
import Snackbar from '@material-ui/core/Snackbar';
import Alert from '@material-ui/lab/Alert';
// import { getCVurl, storeCVurl } from '../services/cvs';
// import { scheduleInterview, canSchedule } from "../services/interview";
import Popover from '@material-ui/core/Popover';
import PopupState, { bindTrigger, bindPopover } from 'material-ui-popup-state';
import Box from "@material-ui/core/Box";
import {createComment, deleteCommment, getComments} from '../services/comments'
import {useAuth} from "../services/authentication";


function CandidateList(props) {

    const [candidates, setCandidates] = useState([]);
    const [candidateIndex, setCandidateIndex] = useState(-1);
    const [messageEmail, setMessageEmail] = useState({
        status: true,
        message: ""
    })
    const [displayedCandidate, setDisplayedCandidate] = useState();
    const [linkedinError, setLinkedinError] = useState(false);
    const [commentsForSelectedCandidate, setCommentsForSelectedCandidate] = useState([]);
    const [goodToChangeComments, setGoodToChangeComments] = useState(false);
    const {currentUser} = useAuth();
    // this is for the comments modal
    const containerRef = React.useRef();

    useEffect(async () => {
        if (candidateIndex === -1) {
            setDisplayedCandidate(null);
        } else if (candidateIndex >= 0) {
            // await retrieveCVUrl();
            setDisplayedCandidate(candidates[candidateIndex]);
            setGoodToChangeComments(false);
            setCommentsForSelectedCandidate([]);
        }
    }, [candidateIndex]);

    useEffect(() => {
        setCandidates(props.candidates);
    }, [props.candidates])

    function handleArrowModal(next) {
        if ((next === 1) && candidateIndex < (candidates.length - 1)) setCandidateIndex(candidateIndex + 1);
        else if ((next === -1) && candidateIndex > 0) setCandidateIndex(candidateIndex - 1);
        else setCandidateIndex(-1);
    }


    // const retrieveCVUrl = async () => {
    //     let candidate = candidates[candidateIndex];
    //     if (candidate.CVurl) {
    //         setCvUrl(candidate.CVurl)
    //     } else {
    //         try {
    //             // let url = await getCVurl(candidate.email);
    //             let url = "Aqui vai o link do CV";
    //             // storeCVurl(candidate.email, url);
    //             setCvUrl(url);
    //         } catch (ex) {
    //             console.log(ex);
    //             setCvUrl('error');
    //         }
    //     }
    // }

    useEffect(async () => {
        if (goodToChangeComments) {
            let comments = await getComments(displayedCandidate.candidateId);
            setCommentsForSelectedCandidate(comments);
        }
        setGoodToChangeComments(false);
    }, [goodToChangeComments]);

    // needs to add the comment, email do candidate, and companies domain
    const sendComments = async (candidateId, comment) => {
        let comments = await createComment(candidateId, comment);
        if (comments) {
            setCommentsForSelectedCandidate([...commentsForSelectedCandidate, {
                id: comments.comment_id,
                comment,
                recruiter: {
                    name: currentUser
                }
            }]);
        }
    }

    const deleteComments = async (commentId) => {
        const response = await deleteCommment(commentId);
        if(response){
            setCommentsForSelectedCandidate(commentsForSelectedCandidate.filter(comment => comment.id !== commentId))
        }
    }
    const editComments = async (commentId) => {
        alert('Por enquanto delete seu comentario e comente denovo')
    }

    return (
        <div>
            <div>
                <table className="table">
                    <thead>
                        <tr>
                            {/* <th scope="col">#</th> */}
                            <th scope="col">Name</th>
                            <th scope="col">Major</th>
                            <th scope="col">University</th>
                            <th scope="col" className="thin-column" colSpan="2"><i className="fas fa-cog" /></th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            candidates.map(
                                (candidate, index) =>
                                    <tr key={candidate.candidateId}>
                                        {/* <th scope="row">{index + 1}</th> */}
                                        <td>{candidate.firstName} {candidate.lastName}</td>
                                        <td>{candidate.major1}</td>
                                        <td>{candidate.university}</td>
                                        <td>
                                            <Tooltip title="Candidate's Profile">
                                                <span onClick={() => setCandidateIndex(index)} className="btn bg-dark-grey"><i className="fas fa-address-card text-white" /></span>
                                            </Tooltip>
                                        </td>
                                        <td>
                                            <Tooltip title={candidate.favorite ? "Remove from Favorites" : "Add to Favorites"}>
                                                <span onClick={() => props.toggleFavorite(candidate.candidateId)} className="btn bg p-1">{candidate.favorite ?
                                                    <i className="fas fa-heart text-danger fa-lg" /> :
                                                    <i className="far fa-heart text-danger fa-lg" />} </span>
                                            </Tooltip>
                                        </td>
                                    </tr>
                            )
                        }
                    </tbody>
                </table>
            </div>
            {
                displayedCandidate &&
                <div id="cv-modal" className="modal">
                    <div className="modal-dialog modal-lg w-100 h-100">
                        <span id="arrow-left" className={"fas fa-chevron-left fa-3x text-white " + (candidateIndex > 0 ? "" : "opacity-0")} onClick={() => handleArrowModal(-1)}>
                        </span>
                        <div className="modal-content">
                            <div className="modal-header text-center">
                                <button onClick={() => setCandidateIndex(-1)} type="button" className="close">
                                    <i className="fas fa-times" />
                                </button>
                            </div>
                            <div className="body-max-width mb-3">
                                <h2 className="modal-title font-weight-bold">{displayedCandidate.firstName} {displayedCandidate.lastName}</h2>
                            </div>
                            <div className="modal-body body-max-width d-flex align-items-center justify-content-center">
                                {(!displayedCandidate.cvUrl
                                    &&
                                    <h4 className="text-center">This candidate did not provide a CV</h4>
                                ) ||
                                    <iframe
                                        title={displayedCandidate.firstName + ' ' + displayedCandidate.lastName}
                                        id="cv-iframe"
                                        src={displayedCandidate.cvUrl}
                                        scrolling="no"
                                        width="100%"
                                        allowFullScreen
                                    />
                                }
                            </div>
                            <div className="modal-footer">
                                <Tooltip title={displayedCandidate.favorite ? "Remove from Favorites" : "Add to Favorites"}>
                                    <span onClick={() => props.toggleFavorite(displayedCandidate.candidateId) && setDisplayedCandidate({...displayedCandidate, favorite: !displayedCandidate.favorite})} className="align-bottom p-2">{displayedCandidate.favorite ?
                                        <i className="fas fa-heart text-danger fa-lg" /> :
                                        <i className="far fa-heart text-danger fa-lg" />} </span>
                                </Tooltip>
                                <Tooltip title="E-mail Candidate">
                                    <a rel="noopener noreferrer" target="_blank" href={"mailto:" + displayedCandidate.email} style={{background : "#383838"}} className="btn m-2"><i className="fa fa-envelope text-white"/></a>
                                </Tooltip>
                                <PopupState variant="popover" popupId="comments-for-candidate">
                                    {(popupState) => (
                                        <Box ref={containerRef}>
                                        <Tooltip title="Comments">
                                            <a {...bindTrigger(popupState)} style={{background : "#F3C515"}} className="btn m-2">
                                                <i className="fa fa-comments text-white" />
                                            </a>
                                        </Tooltip>
                                        
                                        <Popover
                                            {...bindPopover(popupState)}
                                            anchorOrigin={{
                                            vertical: 'top',
                                            horizontal: 'center',
                                            }}
                                            transformOrigin={{
                                            vertical: 'bottom',
                                            horizontal: 'center',
                                            }}
                                            container={containerRef.current}
                                            PaperProps={{
                                                style: { maxWidth: '500px', maxHeight: '800px', overflow: 'auto' },
                                            }}
                                            // container={this.AnchorEl.parentNode}
                                        >
                                            {(popupState.isOpen && goodToChangeComments === false) && setGoodToChangeComments(true)}
                                            <div id="box-comments">
                                                <Box display="grid" gridTemplateColumns="repeat(12, 1fr)" gap={2}>
                                                    <Box gridColumn="span 12">
                                                        <h3 id="header-comments">Comments</h3>
                                                    </Box>
                                                    <Box gridColumn="span 12">
                                                        <Alert severity="info">Only your co-workers will be able to read your comments</Alert>
                                                    </Box>
                                                    <Box gridColumn="span 12">
                                                        {/* goes over the comments for this candidate here */}
                                                        {commentsForSelectedCandidate ?  
                                                        <div className="comments">
                                                        {commentsForSelectedCandidate.map(function(comment){
                                                            return (
                                                                <div key={comment.id}>
                                                                    <div className="row">
                                                                        <div className="col-8">
                                                                            <p style={{textAlign: 'left', fontWeight: 'bold', margin: 0}}>{comment.recruiter.name}</p>
                                                                        </div>
                                                                        {comment.recruiter.name === currentUser &&
                                                                            <>
                                                                           
                                                                            <div className="col-2">
                                                                                <button className="btn" onClick={()=> deleteComments(comment.id)}><i className="fa fa-trash-alt" ></i></button>
                                                                            </div>
                                                                            </>
                                                                        }
                                                                    </div>
                                                                    <div className="col-12">
                                                                        <p style={{textAlign: 'left'}}>{comment.comment}</p>
                                                                    </div>
                                                                </div>
                                                            );
                                                        })}
                                                        </div>
                                                        : <p>There are no comments for the candidate</p> }
                                                    </Box>
                                                    <Box gridColumn="span 12">
                                                        <textarea placeholder="Write your comments here." className="form-control" id="input-comments" rows="3"></textarea>
                                                    </Box>
                                                    <Box gridColumn="span 12">
                                                        <button onClick={function(){
                                                            if(document.getElementById("input-comments").value === ''){
                                                                return;
                                                            }
                                                            sendComments(displayedCandidate.candidateId, document.getElementById("input-comments").value);
                                                            document.getElementById("input-comments").value = '';
                                                        }} id="submit-comment-btn" type="submit" className="btn btn-dark" >Submit</button>
                                                    </Box>
                                                </Box>
                                                </div>
                                            </Popover>
                                        </Box>
                                    )}
                                </PopupState>
                                {displayedCandidate.linkedin === "" ?
                                    <div>
                                        <button onClick={() => setLinkedinError(true)}  style={{background : "#0e76a8"}} className="btn disabled p-8">
                                            <i aria-disabled={true} className="fab fa-linkedin-in text-white" /></button>
                                    </div>
                                    :
                                    <Tooltip title="Open LinkedIn Profile">
                                        <a rel="noopener noreferrer" target="_blank" href={displayedCandidate.linkedin} style={{background : "#0e76a8"}}className="btn"><i className="fab fa-linkedin-in text-white" /></a>
                                    </Tooltip>
                                }
                            </div>
                        </div>
                        <span id="arrow-right" className={"fas fa-chevron-right fa-3x text-white " + (candidateIndex < (candidates.length - 1) ? "" : "opacity-0")} onClick={() => handleArrowModal(1)}>
                        </span>
                    </div>
                </div>
            }
          
            <Snackbar open={linkedinError} autoHideDuration={2000} onClose={() => setLinkedinError(false)}>
                <Alert onClose={() => setLinkedinError(false)} severity="error">
                    This candidate has not provided a LinkedIn profile.
                </Alert>
            </Snackbar>
            <Snackbar open={messageEmail.message !== ""} autoHideDuration={5000} onClose={() => setMessageEmail({
                ...messageEmail,
                message: ""
            })}>
                <Alert onClose={() => setMessageEmail({
                    ...messageEmail,
                    message: ""
                })} severity={messageEmail.status ? "success" : "error"}>
                    {messageEmail.message}
                </Alert>
            </Snackbar>
        </div>
    );
}

export default CandidateList;
