import React, {useRef, useState} from "react";
import logo from "../../images/brasa.png";
import {useHistory} from 'react-router-dom';
// import Tilt from 'react-tilt'
import "../../styles/Login.css";
import {useAuth} from "../../services/authentication";

function Login() {
    const [loginStatusError, setLoginStatusError] = useState(false);
    const [loading, setLoading] = useState(false);
    const emailRef = useRef();
    const passwordRef = useRef();
    const { login } = useAuth();
    const history = useHistory();

    async function handleLogin(e){
        e.preventDefault();
        try{
            setLoading(true);
            await login(emailRef.current.value, passwordRef.current.value);

            setTimeout(() => {
                history.push('/dashboard');
            }, 400);
            // history.push('/dashboard');
        } catch(err) {
            setLoginStatusError(true);
        }
        setLoading(false);
    }

    return (
        <main>
            <div className='background-brasa'>
                <div className="box">
                    <div className="text-instruction text-center">
                        <p className="text-center">
                            {/* <Tilt className="Tilt" options={{ max : 45 }}> */}
                                <img alt="BRASA" className="Tilt-inner" width="40%" src={logo}/>
                            {/* </Tilt> */}
                        </p>
                        <h1>Company Portal</h1>
                    </div>
                    <form onSubmit={handleLogin}>
                        <div className="form-group">
                            <input type="email" className="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter email" ref={emailRef} required/>
                        </div>
                        <div className="form-group">
                            <input type="password" className="form-control" id="exampleInputPassword1" placeholder="Password" ref={passwordRef} required/>
                        </div>

                        <button disabled={loading} className="btn bg-grey text-white w-100">Login</button>
                    </form>
                    <div style={loginStatusError ? {display: "block"} : {display: "none"}}  className="mt-3 alert alert-danger" role="alert">
                        Incorrect email address or password.
                        <button onClick={() => setLoginStatusError(false)} type="button" className="close" data-dismiss="alert" aria-label="Close">
                            <span aria-hidden="true">×</span>
                        </button>
                    </div>
                </div>
            </div>
        </main>
    );
}

export default Login;
