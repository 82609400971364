import React, {useContext, useEffect, useState} from "react";
import {useHistory} from 'react-router-dom';
import axios from 'axios'

const AuthContext = React.createContext();
export function useAuth() {
    return useContext(AuthContext);
}

export function AuthProvider({children}) {
    const [loading, setLoading] = useState(true);
    const [currentUser, setCurrentUser] = useState('');
    const [token, setToken] = useState('');
    
    const history = useHistory();

    useEffect(() => {
        setToken(localStorage.getItem('token'));
        setCurrentUser(localStorage.getItem('currentUser'));
        axios.defaults.headers['x-access-token'] = localStorage.getItem('token');
        setLoading(false);
    }, [])

    useEffect(() => {
        localStorage.setItem('token', token);
        localStorage.setItem('currentUser', currentUser);

        if (token) {
            checkToken(token)
                .then(res => {
                    if (!res) {
                        setToken('');
                        setCurrentUser('');
                    }else{
                        axios.defaults.headers['x-access-token'] = token;
                    }
                })
        }

    }, [token, currentUser])

    async function login(email, password) {
        try {            
            let response = await axios.post(`https://gobrasa.io/api/auth/recruiter/login`,
                {
                    email,
                    password,
                },
            )
            axios.defaults.headers['x-access-token'] = response.data.token;
            setToken(response.data.token);
            setCurrentUser(response.data.recruiter_name);
            return true;
        }
        catch (error) {
            logout();
            console.log(error) // TODO improve error handling
            return false;
        }
    }

    async function logout() {
        setToken('');
        setCurrentUser('');
        localStorage.clear();
    }

    async function checkToken(token) {
        try {
            let response = await axios.post(`https://gobrasa.io/api/auth/verify`, {}, {
                headers: {
                    'x-access-token': token
                },
            })
            return (response.status === 200) ? true : false;
        }
        catch (error) {
            logout();
            console.log(error) // TODO improve error handling
            return false;
        }
    }

    const value = {
        currentUser,
        login,
        logout,
        token,
        history,
    }

    return (
        <AuthContext.Provider value={value}>
            {!loading && children}
        </AuthContext.Provider>
    )
}
