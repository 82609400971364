import React, { useEffect, useState } from "react";
import { useAuth } from "../services/authentication";
import { getFilters, createFilter, editFilter, deleteFilter as df } from "../services/filters";

/**
 * Filter context
 */
const FilterContext = React.createContext({
    filters: [],
    getFilter: () => { },
    saveFilter: () => { },
    deleteFilter: () => { },
    updateFilter: () => { },
});

const FilterContextProvider = ({ children }) => {

    /* context */

    const auth = useAuth();

    /* state */

    const [filters, setFilters] = useState([]);

    /* load filters from user */

    useEffect(() => {
        if (!auth.token) {
            console.error("Unauthorized attempt to access filters");
            return;
        }

        getFilters(auth.token)
            .then(data => {
                setFilters(data);
            })
            .catch(err => {
                console.error("Error loading filters", err);
            })

    }, [auth.token]);

    /* methods */

    /**
     * Gest a filter by name
     * 
     * @param {String} name name of the filter
     * @returns 
     */
    const getFilter = (name) => {

        if (typeof (name) != "string") {
            console.error("Filter name must be a string");
            return;
        };

        if (!filters) {
            return;
        }

        return filters.find(filter => filter.name === name);
    };

    /**
     * Saves a new filter is the name is not already taken
     * 
     * @param {String} name name of the new filter
     * @param {JSON} criteria criteria of the new filter 
     */
    const saveFilter = (name, filtersObj) => {

        if (typeof (name) != "string") {
            console.error("Filter name must be a string");
            return;
        };

        if (typeof (filtersObj) != "object") {
            console.error("Filter criteria must be an object");
            return;
        };

        if (filters.find(filter => filter.name === name)) {
            console.error("Filter already exists");
            return;
        };

        return createFilter(auth.token, { name, criteria: filtersObj })
            .then(() => {
                setFilters(filters.concat({ name, filters: filtersObj }));
                return true;
            })
            .catch(err => {
                console.error("Error saving filter", err);
            });
    };

    /**
     * Deletes a filter
     * 
     * @param {String} name name of the filter to delete
     */
    const deleteFilter = (name) => {

        if (typeof (name) != "string") {
            console.error("Filter name must be a string");
            return;
        }

        df(auth.token, name)
            .then(() => {
                setFilters(filters.filter(filter => filter.name !== name));
            })
            .catch(err => {
                console.error("Error deleting filter", err);
            })
    };

    /**
     * Updates an existing filter
     * 
     * @param {String} name name of the filter to update
     * @param {JSON} criteria criteria of the filter to update
     */
    const updateFilter = (name, filtersObj) => {

        if (typeof (name) != "string") {
            console.error("Filter name must be a string");
            return;
        }

        if (typeof (filtersObj) != "object") {
            console.error("Filter criteria must be an object");
            return;
        }

        editFilter(auth.token, { name, criteria: filtersObj })
            .then(() => {
                setFilters(filters.map(filter => filter.name === name
                    ? { name, filters: filtersObj }
                    : filter));
            })
            .catch(err => {
                console.error("Error updating filter", err);
            });
    };

    /* return context */

    return (
        <FilterContext.Provider
            value={{
                filters,
                getFilter,
                saveFilter,
                deleteFilter,
                updateFilter,
            }}
        >
            {children}
        </FilterContext.Provider>
    );
}

/* export */

export default FilterContext;
export { FilterContextProvider };